"use client"
import * as React from "react"

type QRCodeProps = {
  code?: string
}

export const QRCode = (props: QRCodeProps) => {
  const [render, setRender] = React.useState(false)

  React.useEffect(() => {
    setRender(true)
  }, [])

  return render === true ? (
    <div
      className="flex flex-col items-center md:absolute md:left-1/2 md:transform md:-translate-1/2 md:top-12"
      id="qr-code-wrapper"
    >
      <p className="text-xs">{props.code}</p>
    </div>
  ) : null
}
